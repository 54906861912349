import { get, post } from './http'; // 导入接口域名列表
import axios from 'axios';
const api = {
  // 集团要闻列表
  getNews() {
    // return get('/jtywapi');
    axios.get('https:/www.jtgj.cc' +'/jtywapi',{
      headers:{
      "Content-type":'application/x-www-form-urlencoded',
      },
      }).then(response =>{
      return response.data
      }).catch(error =>{})
  },
  // 公益动态列表
  getWelfare() {
    return get('/gydtapi');
  },
  // 新闻内容接口,参数为新闻的主键ID
  getNewsDetail(params) {
    return get('/ncapi/' + params);
  },
  // 新闻搜索，参数值为汉字做 UrlEncode
  getSearch(params) {
    return get('/searchapi?keywords=' + encodeURI(params));
  },
}

export default api
