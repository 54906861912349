<template>
  <div class="main">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <van-tabbar
      v-model="active"
      active-color="#DE006F"
      inactive-color="#666666"
    >
      <van-tabbar-item
        replace
        :to="item.to"
        v-for="(item, index) in tabData"
        :key="index"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Main",
  data() {
    return {
      active: 0,
      tabData: [
        {
          title: "首页",
          to: "/",
          icon: {
            active: require("../assets/home-active.png"),
            inactive: require("../assets/home-inactive.png"),
          },
        },
        // {
        //   title: "走进金天",
        //   to: "/into/0",
        //   icon: {
        //     active: require("../assets/into-active.png"),
        //     inactive: require("../assets/into-inactive.png"),
        //   },
        // },
        // {
        //   title: "社会责任",
        //   to: "/responsibility/0",
        //   icon: {
        //     active: require("../assets/responsibility-active.png"),
        //     inactive: require("../assets/responsibility-inactive.png"),
        //   },
        // },
        // {
        //   title: "联系我们",
        //   to: "/contact",
        //   icon: {
        //     active: require("../assets/contact-active.png"),
        //     inactive: require("../assets/contact-inactive.png"),
        //   },
        // },
      ],
    };
  },
  watch: {
    "$route.path": function (newVal) {
      if (newVal.indexOf("into") == 1) {
        this.active = 1;
      } else if (newVal.indexOf("responsibility") == 1) {
        this.active = 2;
      } else if (newVal.indexOf("contact") == 1) {
        this.active = 3;
      }
    },
  },
};
</script>
