<template>
  <div class="container">
    <Header />
    <div class="content">
      <div class="swiper">
        <van-swipe :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in images" :key="index">
            <router-link :to="item.to">
              <van-image height="200" fit="fill" :src="item.image" />
            </router-link>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div style="height: 0.2rem; background-color: #eee"></div>
      <!-- <div class="activity">
        <div class="label">活动</div>
        <div class="text">免费领礼品活动...</div>
      </div> -->
      <div style="height: 0.3rem; background-color: #eee"></div>
      <div class="news" style="width: 100%;">
        <!-- <div class="title">
          <div class="text">集团要闻</div>
          <router-link to="/newslist" class="more">更多&nbsp;></router-link>
        </div> -->
        <!-- <div v-for="(item, index) in newsData" :key="index"> -->
          <!-- <news-item :data="item" /> -->
           <img src='../../assets/1.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">
           <img src='../../assets/2.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">
           <img src='../../assets/3.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">
           <img src='../../assets/4.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">
           <img src='../../assets/5.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">
           <img src='../../assets/6.jpg' alt="" style="width: 80%;margin: 0 auto;margin-top: 20px;">

        <!-- </div> -->
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import NewsItem from "@/components/NewsItem";

export default {
  data() {
    return {
      newsData: [
      "../../assets/1.jpg",
      "../../assets/2.jpg",
      "../../assets/3.jpg",
      "../../assets/4.jpg",
      "../../assets/5.jpg",
      "../../assets/6.jpg",
      ],
      images: [
        {to:'',image:require("../../assets/11.jpg")},
        {to:'',image:require("../../assets/22.jpg")},
        // {to:'',image:require("../../assets/33.jpg")},
        {to:'',image:require("../../assets/44.jpg")},
        // {to:'',image:require("../../assets/55.jpg")},
      ],
    };
  },
  components: {
    Header,
    Footer,
    NewsItem,
  },
  mounted() {
    // this.getNews();
  },
  methods: {
    getNews() {
      this.$api.getNews().then((res) => {
        this.newsData = res.slice(0, 10);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  .content {
    margin-top: 3.5rem;
    .swiper {
      background-color: #eee;
    }
    .activity {
      height: 3rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      .label {
        margin-left: 1rem;
        color: #de006f;
        font-size: 1rem;
        font-weight: bold;
        border: solid #de006f 0.1rem;
        border-radius: 0.5rem;
        padding: 0.2rem;
      }
      .text {
        color: #666666;
        font-size: 1.1rem;
        margin-left: 1rem;
      }
    }
    .news {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0.5rem;
        align-items: center;
        height: 3rem;
        border-bottom: solid #eee 0.15rem;
        .text {
          font-size: 1.3rem;
          font-weight: bold;
        }
        .more {
          color: #666;
        }
      }
    }
  }
}
</style>