import Vue from 'vue';
import VueRouter from 'vue-router';
import Main from '../views/Main.vue';
import Home from '../views/page/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Main,
    meta: {
      keepAlive: true,
    },
    children: [
      {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/into/:tabIndex',
        name: 'into',
        component: () => import('../views/page/Into.vue'),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/responsibility/:tabIndex',
        name: 'responsibility',
        component: () => import('../views/page/Responsibility.vue'),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../views/page/Contact.vue'),
        meta: {
          keepAlive: true,
        },
      },
    ]
  },
  {
    path: '/newslist',
    name: 'NewsList',
    meta: {
      keepAlive: false,
    },
    component: () => import('../views/page/NewsList.vue')
  },
  {
    path: '/newsdetail/:newsID',
    name: 'NewsDetail',
    meta: {
      keepAlive: false,
    },
    component: () => import('../views/page/NewsDetail.vue')
  },
  {
    path: '/search',
    name: 'SearchPage',
    meta: {
      keepAlive: false,
    },
    component: () => import('../views/page/SearchPage.vue')
  }
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router;
